import BurgerButton from '../BurgerButton'
import classNames from 'classnames'
import eventEmitter from '../../eventEmitter'
import { graphql, StaticQuery } from 'gatsby'
import LanguageSelection from '../LanguageSelection'
import Link from '../LocalizedLink'
import React, { Component } from 'react'
import ReactSVG from 'react-svg'
import Tray from '../Tray'

import Triangle from './Triangle'
import { isMobile } from '../../shared'

import styles from './styles.module.css'
import { FormattedMessage } from 'react-intl'

let displayedWebNavbar = undefined
let displayedMobileNavbar = undefined

class Navbar extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isTrayVisible: false,
      scrolled: false,
      scrollY: 0,
      visible: true,
      imageToShow: '',
      hasScrolled: false,
    }
  }

  componentDidMount() {
    this.setState({ isMobile: isMobile() })
    this.toggleNavbarDisplay()
    window.addEventListener('resize', this.updateViewport)
    eventEmitter.on('openTray', id => {
      this.setState({ isTrayVisible: true }, () => {
        setTimeout(() => {
          eventEmitter.emit('openItem', id)
        }, 700)
      })
    })
    window.addEventListener('scroll', this.scroll);
  }

  componentWillUnmount = () => {
    window.removeEventListener('scroll', this.scroll)
    window.removeEventListener('resize', this.updateViewport)
    eventEmitter.off()
  }

  updateViewport = () => {
    const mobile = isMobile()
    if (this.state.isMobile !== mobile) {
      this.toggleNavbarDisplay()
      this.setState({ isMobile: mobile })
    }
  }

  toggleNavbarDisplay = () => {
    displayedWebNavbar = isMobile() ? styles.hidden : styles.displayed
    displayedMobileNavbar = isMobile() ? styles.displayed : styles.hidden
  }

  toggleTray = () => {
    if (!this.state.isMobile) {
      eventEmitter.emit('displayResearchPicture', false)
    }
    this.setState({ isTrayVisible: !this.state.isTrayVisible }, () => {
      if (!this.state.isTrayVisible) {
        eventEmitter.emit('closeTray')
      }
    })
  }

  closeTray = () => {
    if (this.state.isTrayVisible) {
      this.setState({
        isTrayVisible: false,
      })
      eventEmitter.emit('closeTray')
    }
  }

  scroll = () => {
    if (!this.state.isTrayVisible) {
      if (this.state.isMobile) {
        this.setState({
          hasScrolled: true,
        })
        if (window.scrollY < 1 && this.state.scrolled) {
          this.setState({
            scrolled: false,
          })
        }

        if (window.scrollY < this.state.scrollY) {
          this.setState({
            visible: true,
            scrolled: window.scrollY >= 1,
          })
        } else if (window.scrollY >= 1) {
          this.setState({
            visible: false,
          })
        }
        this.setState({
          scrollY: window.scrollY,
        })
      }
    }
  }

  render() {
    return (
      <StaticQuery
        query={graphql`
          {
            cockpitMenu(cockpitId: { eq: "5d389f9574d1a20061208362" }) {
              logos {
                value {
                  image {
                    value {
                      publicURL
                    }
                  }
                }
              }
              catchphrase {
                value {
                  childMarkdownRemark {
                    html
                  }
                }
              }
            }
          }
        `}
        render={staticData => (
          <div>
            {this.state.isMobile && this.state.isMobile !== undefined ? (
              <div className={displayedMobileNavbar}>
                <div
                  className={classNames({
                    [styles.opaqueNavbar]: true,
                    [styles.hiddenUp]: !this.state.visible,
                  })}
                >
                  <div className={styles.main}>
                    <Link to="/" className={styles.home}>
                      {}
                      <ReactSVG
                        className={styles.logo}
                        src={(() => {
                          if (this.state.isMobile) {
                            return staticData.cockpitMenu.logos.value[0].image
                              .value.publicURL
                          } else {
                            return staticData.cockpitMenu.logos.value[
                              Math.floor(
                                Math.random() *
                                  staticData.cockpitMenu.logos.value.length
                              )
                            ].image.value.publicURL
                          }
                        })()}
                      />
                    </Link>
                    <div className={styles.burgerButton}>
                      <BurgerButton
                        isToggled={!this.state.isTrayVisible}
                        toggleTray={this.toggleTray}
                      />
                    </div>
                  </div>
                </div>
                <div
                  className={classNames({
                    [styles.tray]: true,
                    [styles.hiddenTray]: !this.state.isTrayVisible,
                  })}
                >
                  <div className={styles.trayItems}>
                    {this.state.isMobile ? (
                      <Tray
                        visible={this.state.isTrayVisible}
                        toggleTray={this.toggleTray}
                        isMobile={true}
                        loading={this.props.loading}
                        location={this.props.location}
                      />
                    ) : (
                      <></>
                    )}
                  </div>
                  <div className={styles.pages}>
                    <Link
                      to="/about"
                      className={classNames({
                        [styles.link]: true,
                        [styles.aboutLink]: true,
                      })}
                    >
                      About
                    </Link>
                    <Link
                      to="/covid"
                      className={classNames({
                        [styles.link]: true,
                        [styles.aboutLink]: true,
                      })}
                    >
                      COVID-19
                    </Link>
                    <Link
                      to="/team"
                      className={classNames({
                        [styles.link]: true,
                        [styles.teamLink]: true,
                      })}
                    >
                      Team
                    </Link>
                  </div>
                  <div className={styles.helpSection}>
                    <Link className={styles.helpLink} to="/help/">
                      <FormattedMessage
                        id="none"
                        defaultMessage={'Get help now'}
                      />
                    </Link>
                  </div>
                </div>
              </div>
            ) : (
              <div></div>
            )}
            {!this.state.isMobile && this.state.isMobile !== undefined ? (
              <div className={displayedWebNavbar}>
                <div className={styles.opaqueNavbar}>
                  <div className={styles.main}>
                    <BurgerButton
                      isToggled={!this.state.isTrayVisible}
                      toggleTray={this.toggleTray}
                    />
                    {this.state.isTrayVisible ? (
                      <Triangle />
                    ) : (
                      <div className={styles.noTriangle} />
                    )}
                    <div onClick={this.closeTray}>
                      <Link to="/" className={styles.home}>
                        <ReactSVG
                          className={styles.logo}
                          src={(() => {
                            return staticData.cockpitMenu.logos.value[
                              Math.floor(
                                Math.random() *
                                  staticData.cockpitMenu.logos.value.length
                              )
                            ].image.value.publicURL
                          })()}
                        />
                      </Link>
                    </div>
                    <div
                      className={styles.catchphrase}
                      dangerouslySetInnerHTML={{
                        __html: staticData.cockpitMenu.catchphrase.value.childMarkdownRemark.html.toUpperCase(),
                      }}
                    />
                  </div>
                  <div className={styles.pages}>
                    <div onClick={this.closeTray} className={styles.about}>
                      <Link className={styles.link} to="/about">
                        <FormattedMessage id="none" defaultMessage="About" />
                      </Link>
                    </div>
                    <div onClick={this.closeTray} className={styles.about}>
                      <Link className={styles.link} to="/covid">
                        <FormattedMessage id="none" defaultMessage="COVID-19" />
                      </Link>
                    </div>
                    <div onClick={this.closeTray} className={styles.team}>
                      <Link className={styles.link} to="/team">
                        <FormattedMessage id="none" defaultMessage="Team" />
                      </Link>
                    </div>
                    <div
                      onClick={this.closeTray}
                      className={classNames({
                        [styles.help]: true,
                        [styles.hasTriangle]:
                          this.props.pageContext.slug === '/help/',
                      })}
                    >
                      <Link className={styles.linkWhite} to="/help">
                        <FormattedMessage
                          id="none"
                          defaultMessage={'Get help now'}
                        />
                      </Link>
                    </div>
                  </div>
                  <div className={styles.languagesWhite}>
                    <LanguageSelection pageContext={this.props.pageContext} />
                  </div>
                </div>
                {!this.state.isMobile ? (
                  <Tray
                    visible={this.state.isTrayVisible && !this.state.isMobile}
                    toggleTray={this.toggleTray}
                    loading={this.props.loading}
                    isMobile={false}
                    location={this.props.location}
                  />
                ) : (
                  <></>
                )}
              </div>
            ) : (
              <></>
            )}
          </div>
        )}
      />
    )
  }
}

export default Navbar
